import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-relation',
  templateUrl: './relation.component.html',
  styleUrls: ['./relation.component.css']
})
export class RelationComponent implements OnInit {

  accounts: any; relations:any; filteredAccount: any[] = []; response: any; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1,10);
  }
  constructor(
    private api: ApiService, 
    private use: NavComponent, 
    private _api: ManagerService, 
    private _exp: ExportExcelService
  ) { }

  ngOnInit() {
    this.getAllRelations();
  }

  getAllRelations() {
    this.api.get('Relation?page=1&limit=10').subscribe((data: any) => {
      this.relations = data; this.response = data; console.log(data);
      this.filteredAccount = this.relations.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Relation List Fail', 'Unable to Fetch Relation List');
    });
  }

  getMore(page,limit) {
    this.api.get('Relation?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.relations = data; this.response = data; console.log(data);
      this.filteredAccount = this.relations.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Relation List Fail', 'Unable to Fetch Relation List');
    });
  }

  Delete(relation) {
    this.api.delete('Relation/'+relation.accountid).subscribe((data: any) => {
      this.getMore(1,10);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Relation List Fail', 'Unable to Fetch Relation List');
    });
  }
  
  Export() {
    this.Sort().then((data)=>{
      this._exp.exportAsExcelFile(data, 'relation');
    });
  }

  async Sort() {
    const relations = [];
    await this.filteredAccount.forEach((c)=>{
      const relation = { UserId: c.UserId, merchantId: c.merchantId, accountId: c.accountId, 
        PolicyNumber: c.PolicyNumber, Relationship: c.Relationship, Lastname: c.Lastname, 
        Firstname: c.Firstname, 
      };
        relations.push(relation);
    });
    return relations;
  }

  serachResult(searchString: string) {
    return this.relations.filter(a => a.number.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.customer.fullname.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.accounttype.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.accounttype.basetype.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }


}






