import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-teller-summary',
  templateUrl: './teller-summary.component.html',
  styleUrls: ['./teller-summary.component.css']
})
export class TellerSummaryComponent implements OnInit {
  dat: any; cash: any; search: any; trans: any[] = []; rp: any;
  filtered: any[]; private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.serachResult(value);
  }
  constructor(private _api: TellerService, private _use: NavComponent) { }

  ngOnInit() {
    this.init();
    this.getTodaySummary();
  }

  getTodaySummary() {
    const id = this._use.active.Id; const date = this._use.date;
    this._api.getTellerSummary(id, date).subscribe((data: any) => {
      this.cash = data.cash; this.trans = data.trans; this.filtered = data.trans;
      this._use.showMsg('success', 'Teller Summary', 'Successfully fetch current Teller Summary');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Teller Summary Fail', 'Unable to Get Current Balance'); });
  }

  getSummary() {
    const id = this._use.active.Id;
    this._api.getTellerSummary(id, this.rp.date).subscribe((data: any) => {
      this.cash = data.cash; this.trans = data.trans; this.filtered = data.trans;
      this._use.showMsg('success', 'Teller Summary', 'Successfully fetch Teller Balance for Date: ' + this.rp.date);
    }, (err: HttpErrorResponse) => {
        this._use.showMsg('error', 'Teller Summary Fail', 'Unable to Get Summary for Date: ' + this.rp.date); });
  }

  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.trans.filter(a => a.account.number.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.reference.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

  init(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.rp = { id: this._use.active.id, date: this._use.date };
      this.cash = { opening: 0, credit: 0, debit: 0, balance: 0 };
      this.search = { data: '', dat: null };
    }
  }
}
