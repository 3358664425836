import { Component, OnInit, ViewChild } from '@angular/core';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'app-setup-package',
  templateUrl: './setup-package.component.html',
  styleUrls: ['./setup-package.component.css']
})
export class SetupPackageComponent implements OnInit {

  @ViewChild('sessionForm') public any: NgForm;
  pack: any; add: Boolean; edit: Boolean = false; curr: Date; response: any; search: string = '';
  packages: any[] = []; merchants: any[] = []; filtered: any[]; 
  types:any[] = ["Motor", "Marine"];
  periods:any[] = ["DAILY", "WEEKLY", "MONTHLY", "YEARLY"]; user: any;
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    // this.filtered = this.searchResult(value);
    this.getMore(1,10)
  }
  constructor(private api: ApiService, private use: NavComponent) { }

  ngOnInit() {
    this.user = this.use.active;
    this.curr = new Date();
    this.formReset();
    this.getAllPackages();
    if(this.use.active.typ ==="Admin" || this.use.active.typ === "Support") {
      this.getMerchant();
    }
    //this.getMerchant();
  }

  getAllPackages() {
    this.api.get('package').subscribe((data: any) => {
      this.packages = data.data; this.filtered = data.data; this.response = data;
      // console.log(data);
      this.use.showMsg('success', 'Package List', 'Fetched Package list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Package List Fail', 'Unable to Fetch Package List');
    });
  }

  getMore(page,limit) {
    this.api.get('package?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.packages = data.data; this.filtered = data.data; this.response = data; // console.log(data);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Package List Fail', 'Unable to Fetch Package List');
    });
  }

  getMerchant() {
    this.api.get('merchant?limit=100').subscribe((data: any) => {
      this.merchants = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  editPackage(data) {
    this.pack = data;
    this.add=true; this.edit = true;
  }

  // delPackage(dat) {
  //   this.api.delete('package/'+dat.chargeid).subscribe((data: any) => {
  //     this.packages.slice(dat);
  //   }, (err: HttpErrorResponse) => { 
  //     this.use.showMsg('error', 'Delete Fail', 'Unable to Delete Charge'); 
  //   });
  // }

  // savePackage() {
  //   if(this.pack.chargeid > 0) {
  //     this.putPackage();
  //   } else {
  //     this.postPackage();
  //   }
  // }

  postPackage() {
    this.api.post('package',this.pack).subscribe((data: any) => {
      this.getAllPackages(); this.formReset();
      this.use.showMsg('success', 'Package Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Package');
    });
  }

  putPackage() {
    this.pack.muserid = this.use.active.id; this.pack.mdate = new Date();
    this.api.put('package/', this.pack).subscribe((data: any) => {
      this.getAllPackages(); this.formReset();
      this.use.showMsg('success', 'Package Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.pack = {
        id: 0, code: "", type: "", name: "", period: "", fixed: true, amount: "", percentage: 0, //value: 0,  
        merchantid: "", status: true, no_of_seat: "", extra_seat_charge: "", fire_theft_rate: "",
        active: true,  userid: this.use.active.id, date: new Date(), muserid: null, mdate: null
      };
      this.edit = false;
    }
  }

  // back() {
  //   this.formReset();
  // }

  listView() {
    this.add = true; this.edit = false;
  }

  back() {
    this.add = false;
  }

}




