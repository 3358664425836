import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SigninService } from 'src/app/services/signin.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  public active: any; res: any; public date: any; bal:any;
  constructor(private api: ApiService, private ser: SigninService, private _nav: Router, public toastr: ToastrService) { }

  ngOnInit() {
    this.init();
    this.active = this.ser.userDetails();
    this.date = JSON.parse(localStorage.getItem('Date'));
    // console.log(this.active);
    // this.getClaims();
  }

  showMsg(type, title, message) {
    if (type === 'success') {
      this.toastr.success(message, title);
    } else if (type === 'warning') {
      this.toastr.warning(message, title);
    } else if (type === 'error') {
      this.toastr.error(message, title);
    }
  }

  getClaims() {
    this.ser.getClaim().subscribe((data: any) => {
      // console.log(data);
      // this.showMsg('success', 'Claims', JSON.stringify(data));
    }, (err: HttpErrorResponse) => { console.log(err); /*this._nav.navigate(['/login']);*/ });
  }

  logOut() {
    this.ser.logOut(this.active.unique_name).subscribe((data: any) => {
      this.res = data; localStorage.removeItem('Token'); // alert('Logout successfully');
      this.showMsg('success', 'Logout', 'You have been logged out successfully');
      this._nav.navigate(['/login']);
    }, (err: HttpErrorResponse) => {console.log(err);
      localStorage.removeItem('Token');
      this._nav.navigate(['/login']);
    });
  }

  init() {
    this.active = { fullname: '', image: '', usertype: '', };
  }

}
