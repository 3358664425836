import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-beneficiary',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.css']
})
export class BeneficiaryComponent implements OnInit {

  accounts: any; beneficiaries: any; ben:any; user: any; merchants: any[] = [];
  add: Boolean; edit: Boolean; filteredAccount: any[] = []; response: any; search: string = '';
  gender = ["Female", "Male"]; 
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1,10);
    // this.filteredAccount = this.serachResult(value);
  }
  
  listView() {
    this.add = true; this.edit = false;
  }

  back() {
    this.add = false;
  }
  constructor(
    private api: ApiService, 
    private use: NavComponent, 
    private _api: ManagerService, 
    private _exp: ExportExcelService
  ) { }

  ngOnInit() {
    // this.user = this.use.active;
    // this.init();
    // if(this.use.active.typ ==="Admin" || this.use.active.typ === "Support") {
    //   this.getMerchant();
    // }
    this.getAllBeneficiaries();
  }

  // getMerchant() {
  //   this.api.get('merchant?limit=100').subscribe((data: any) => {
  //     this.merchants = data.data;
  //   }, (err: HttpErrorResponse) => {
  //     this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
  //   });
  // }

  // postBeneficiary() {
  //   this.api.post('Beneficiary',this.ben).subscribe((data: any) => {
  //     this.init();
  //     this.use.showMsg('success', 'Beneficiary Added', 'Added to list Successfully');
  //   }, (err: HttpErrorResponse) => {
  //     this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Beneficiary');
  //   });
  // }

  // putBeneficiary() {
  //   this.api.put('Beneficiary/', this.ben).subscribe((data: any) => {
  //     this.init();
  //     this.use.showMsg('success', 'Beneficiary Updated', 'Details Updated Successfully');
  //   }, (err: HttpErrorResponse) => {
  //     this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
  //   });
  // }

  // init() {
  //   this.ben = { UserId: 0, merchant: '', policyNumber: '', account: '', BenefitPercentage: '', 
  //             Mobile: '', DateofBirth: '', Gender: '', Lastname: '', Firstname: '', merchantid: "", }
  // }

  getAllBeneficiaries() {
    this.api.get('Beneficiary?page=1&limit=10').subscribe((data: any) => {
      this.beneficiaries = data; this.response = data; console.log(data);
      this.filteredAccount = this.beneficiaries.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Beneficiary List Fail', 'Unable to Fetch Beneficiary List');
    });
  }

  getMore(page,limit) {
    this.api.get('Beneficiary?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.beneficiaries = data; this.response = data; console.log(data);
      this.filteredAccount = this.beneficiaries.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Beneficiary List Fail', 'Unable to Fetch Beneficiary List');
    });
  }

  Delete(beneficiary) {
    this.api.delete('Beneficiary/'+beneficiary.accountid).subscribe((data: any) => {
      this.getMore(1,10);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Beneficiary List Fail', 'Unable to Fetch Beneficiary List');
    });
  }
  
  Export() {
    this.Sort().then((data)=>{
      this._exp.exportAsExcelFile(data, 'beneficiary');
    });
  }

  async Sort() {
    const beneficiaries = [];
    await this.filteredAccount.forEach((c)=>{
      const beneficiary = { 
        UserId: c.UserId,  merchant: c.merchant,  policyNumber: c.policyNumber,  account: c.account, 
        BenefitPercentage: c.BenefitPercentage,  Mobile: c.Mobile,  DateofBirth: c.DateofBirth,  
        Gender: c.Gender, Lastname: c.Lastname, Firstname: c.Firstname,
      };
        beneficiaries.push(beneficiary);
    });
    return beneficiaries;
  }

  serachResult(searchString: string) {
    return this.accounts.filter(a => a.number.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.customer.fullname.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.accounttype.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.accounttype.basetype.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }


}

