import { Routes } from '@angular/router';
import { NavComponent } from './traits/nav/nav.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { TellerTransferComponent } from './views/teller/teller-transfer/teller-transfer.component';
import { TellerVoucherComponent } from './views/teller/teller-voucher/teller-voucher.component';
import { TellerSummaryComponent } from './views/teller/teller-summary/teller-summary.component';
import { EmployeeComponent } from './views/employee/employee.component';
import { FinanceDaybookComponent } from './views/finance/finance-daybook/finance-daybook.component';
import { FinanceCashbookComponent } from './views/finance/finance-cashbook/finance-cashbook.component';
import { FinancePaymentComponent } from './views/finance/finance-payment/finance-payment.component';
import { FinanceSummaryComponent } from './views/finance/finance-summary/finance-summary.component';
import { FinanceReportComponent } from './views/finance/finance-report/finance-report.component';
import { FinancePayrollComponent } from './views/finance/finance-payroll/finance-payroll.component';
import { SetupSequenceComponent } from './views/setup/setup-sequence/setup-sequence.component';
import { SetupNorminalComponent } from './views/setup/setup-norminal/setup-norminal.component';
import { SetupUserComponent } from './views/setup/setup-user/setup-user.component';
import { SetupSmslogsComponent } from './views/setup/setup-smslogs/setup-smslogs.component';
import { LoginComponent } from './views/auth/login/login.component';

import { AddemployeeComponent } from './views/employee/addemployee/addemployee.component';
import { SetupSmsComponent } from './views/setup/setup-sms/setup-sms.component';
import { NotfoundComponent } from './views/error/notfound/notfound.component';
import { AuthGuard } from './guards/auth.guard';
import { SetupTransitComponent } from './views/setup/setup-transit/setup-transit.component';
import { ForgetPasswordComponent } from './views/auth/forget-password/forget-password.component';
import { SetupLocationComponent } from './views/setup/setup-location/setup-location.component';
import { ExitpageGuard } from './guards/exitpage.guard';
import { SetupTellerComponent } from './views/setup/setup-teller/setup-teller.component';
import { CustomerComponent } from './views/customer/customer.component';
import { AddCustomerComponent } from './views/customer/add-customer/add-customer.component';

import { AccountGuard } from './guards/account.guard';
import { AdminGuard } from './guards/admin.guard';
import { ManagerGuard } from './guards/manager.guard';

import { TellerPaymentComponent } from './views/teller/teller-payment/teller-payment.component';
import { AlertsComponent } from './views/alerts/alerts.component';
import { AddAccountComponent } from './views/accounts/add-account/add-account.component';
import { AccountsComponent } from './views/accounts/accounts.component';
import { AccountApprovalComponent } from './views/accounts/account-approval/account-approval.component';
import { SetupCotComponent } from './views/setup/setup-cot/setup-cot.component';
import { SetupSessionComponent } from './views/setup/setup-session/setup-session.component';
import { SetupBranchComponent } from './views/setup/setup-branch/setup-branch.component';
import { AccountStatusComponent } from './views/accounts/account-status/account-status.component';
import { ApplyCotComponent } from './views/finance/apply-cot/apply-cot.component';
import { SetupAccountComponent } from './views/setup/setup-account/setup-account.component';
import { FinanceTransferComponent } from './views/finance/finance-transfer/finance-transfer.component';
import { AccountChequeComponent } from './views/accounts/account-cheque/account-cheque.component';
import { FinanceAccountComponent } from './views/finance/finance-account/finance-account.component';
import { FinanceReceiptComponent } from './views/finance/finance-receipt/finance-receipt.component';

import { MigrationsComponent } from './views/migrations/migrations.component';
import { RelationOfficersComponent } from './views/relation-officers/relation-officers.component';
import { AddRelationOfficerComponent } from './views/relation-officers/add-relation-officer/add-relation-officer.component';
import { ChangeRelationOfficerComponent } from './views/relation-officers/change-relation-officer/change-relation-officer.component';
import { GroupsComponent } from './views/groups/groups.component';
import { AddGroupComponent } from './views/groups/add-group/add-group.component';

import { FinanceFundManagerComponent } from './views/finance/finance-fund-manager/finance-fund-manager.component';
import { AgentsComponent } from './views/agents/agents.component';
import { AddAgentComponent } from './views/agents/add-agent/add-agent.component';

import { CustomerReportComponent } from './views/customer/customer-report/customer-report.component';
import { AccountReportComponent } from './views/accounts/account-report/account-report.component';
import { GroupReportComponent } from './views/groups/group-report/group-report.component';
import { ViewGroupComponent } from './views/groups/view-group/view-group.component';
import { SetupCompanyComponent } from './views/setup/setup-company/setup-company.component';
import { ViewAgentComponent } from './views/agents/view-agent/view-agent.component';
import { SetupChargeComponent } from './views/setup/setup-charge/setup-charge.component';
import { ViewCustomerComponent } from './views/customer/view-customer/view-customer.component';

import { ApprovalsComponent } from './views/approvals/approvals.component';
import { ViewRelationOfficerComponent } from './views/relation-officers/view-relation-officer/view-relation-officer.component';
import { MotoInsuranceComponent } from './views/finance/moto-insurance/moto-insurance.component';
import { SetupPackageComponent } from './views/setup/setup-package/setup-package.component';
import { AccountAutodebitComponent } from './views/accounts/account-autodebit/account-autodebit.component';
import { SetupCountryComponent } from './views/setup/setup-country/setup-country.component';
import { RelationComponent } from './views/accounts/relation/relation.component';
import { BeneficiaryComponent } from './views/accounts/beneficiary/beneficiary.component';


export const appRoute: Routes = [
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'forgot_password', component: ForgetPasswordComponent },
  {
    path: '', component: NavComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardComponent, data: { title: 'dashboard' } },
      { path: 'dashboard', component: DashboardComponent, data: { title: 'dashboard' } },
      { path: 'alerts', component: AlertsComponent, data: { title: 'alerts' } },
      {
        path: 'customer', canActivate: [AuthGuard],
        children: [
          { path: '', component: CustomerComponent, data: { title: 'Customer' } },
          { path: 'add', component: AddCustomerComponent, canDeactivate: [ExitpageGuard] },
          { path: 'add/:type', component: AddCustomerComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:id', component: AddCustomerComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:type/:id', component: AddCustomerComponent, canDeactivate: [ExitpageGuard] },
          { path: 'view/:id', component: ViewCustomerComponent }, // canDeactivate: [ExitpageGuard] },
        ]
      },
      {
        path: 'customer-service',  canActivate: [AuthGuard],
        children: [
          { path: '', component: CustomerReportComponent, data: { title: 'Customer Report' } },
          { path: 'customer', component: CustomerReportComponent, data: { title: 'Customer Report' } },
          { path: 'group', component: GroupReportComponent },
          { path: 'account', component: AccountReportComponent },
        ]
      },
      {
        path: 'agents', canActivate: [AuthGuard,ManagerGuard],
        children: [
          { path: '', component: AgentsComponent, data: { title: 'Groups' } },
          { path: 'add', component: AddAgentComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:id', component: AddAgentComponent, canDeactivate: [ExitpageGuard], canActivate: [AccountGuard], },
          { path: 'view/:id', component: ViewAgentComponent },
          { path: 'view', component: ViewAgentComponent },
        ]
      },
      {
        path: 'groups', canActivate: [AuthGuard,ManagerGuard],
        children: [
          { path: '', component: GroupsComponent, data: { title: 'Groups' } },
          { path: 'add', component: AddGroupComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:id', component: AddGroupComponent, canDeactivate: [ExitpageGuard], canActivate: [AccountGuard] },
          { path: 'view/:id', component: ViewGroupComponent },
        ]
      },
      {
        path: 'employee', canActivate: [AuthGuard,AccountGuard],
        children: [
          { path: '', component: EmployeeComponent, data: { title: 'employee' } },
          { path: 'add', component: AddemployeeComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:id', component: AddemployeeComponent, canDeactivate: [ExitpageGuard] },
          { path: 'users', component: SetupUserComponent, data: { title: 'user' } },
          { path: 'teller', component: SetupTellerComponent, data: { title: 'Teller' } },
        ]
      },
      {
        path: 'officers', canActivate: [AuthGuard,ManagerGuard],
        children: [
          { path: '', component: RelationOfficersComponent, data: { title: 'Relation Officer' } },
          { path: 'add', component: AddRelationOfficerComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:id', component: AddRelationOfficerComponent, canDeactivate: [ExitpageGuard], canActivate: [AccountGuard] },
          { path: 'view/:id', component: ViewRelationOfficerComponent, data: { title: 'Change' } },
          { path: 'change', component: ChangeRelationOfficerComponent, data: { title: 'Change' }, canActivate: [AccountGuard] },
          // { path: 'teller', component: SetupTellerComponent, data: { title: 'Teller' } },
        ]
      },
      {
        path: 'accounts', canActivate: [AuthGuard],
        children: [
          { path: 'all', component: AccountsComponent },
          { path: 'apply', component: ApplyCotComponent },
          { path: 'active', component: AccountsComponent },
          { path: 'cheque', component: AccountChequeComponent },
          { path: 'relation', component: RelationComponent },
          { path: 'beneficiary', component: BeneficiaryComponent },
          { path: 'approval', component: AccountApprovalComponent, canActivate: [AccountGuard], },
          { path: 'autodebit', component: AccountAutodebitComponent, canActivate: [AccountGuard], },
          { path: 'setup', component: SetupAccountComponent, canActivate: [AccountGuard] },
          { path: 'add', component: AddAccountComponent, canDeactivate: [ExitpageGuard] },
          { path: 'edit/:id', component: AddAccountComponent, canActivate: [AccountGuard], canDeactivate: [ExitpageGuard] },
          // { path: ':status', component: AccountStatusComponent, canActivate: [AccountGuard], },
        ]
      },
      {
        path: 'teller', canActivate: [AuthGuard],
        children: [
          { path: '', component: TellerPaymentComponent },
          { path: 'payment', component: TellerPaymentComponent },
          { path: 'voucher', component: TellerVoucherComponent, canDeactivate: [ExitpageGuard] },
          { path: 'summary', component: TellerSummaryComponent },
          { path: 'transfer', component: TellerTransferComponent, canDeactivate: [ExitpageGuard] },
        ]
      },
      {
        path: 'finance', canActivate: [AuthGuard],
        children: [
          { path: '', component: FinanceCashbookComponent },
          { path: 'approval', component: ApprovalsComponent, canActivate: [ManagerGuard]},
          { path: 'apply', component: ApplyCotComponent },
          { path: 'accounts', component: FinanceAccountComponent },
          { path: 'receipt', component: FinanceReceiptComponent },
          { path: 'cashbook', component: FinanceCashbookComponent },
          { path: 'manager', component: FinanceFundManagerComponent },
          { path: 'payment', component: FinancePaymentComponent },
          { path: 'daybook', component: FinanceDaybookComponent },
          { path: 'transfer', component: FinanceTransferComponent },
          { path: 'payroll', component: FinancePayrollComponent },
          { path: 'summary', component: FinanceSummaryComponent },
          { path: 'report', component: FinanceReportComponent },
          { path: 'insurance', 
            children: [
              { path: '', component: MotoInsuranceComponent, data: { title: 'Insurance' } },
              { path: 'view/:id', component: ViewCustomerComponent }, // canDeactivate: [ExitpageGuard] },
              // { path: 'view', component: ViewCustomerComponent }, // canDeactivate: [ExitpageGuard] },
            ]
          },
        ]
      },
      { path: 'migrations', component: MigrationsComponent, data: { title: 'Migration Change' }, canActivate: [AdminGuard] },
      {
        path: 'settings', canActivate: [AuthGuard,AccountGuard],
        children: [
          { path: '', component: SetupSmsComponent },
          { path: 'charge', component: SetupChargeComponent },
          { path: 'session', component: SetupSessionComponent },
          { path: 'sequence', component: SetupSequenceComponent },
          { path: 'smslog', component: SetupSmslogsComponent },
          { path: 'users', component: SetupUserComponent },
          { path: 'location', component: SetupLocationComponent },
          { path: 'account', component: SetupAccountComponent },
          { path: 'package', component: SetupPackageComponent },
        ]
      },{
        path: 'setup', canActivate: [AuthGuard,AdminGuard],
        children: [
          { path: '', component: SetupSmsComponent },
          { path: 'merchant', component: SetupCompanyComponent },
          { path: 'charge', component: SetupChargeComponent },
          { path: 'cot', component: SetupCotComponent },
          { path: 'country', component: SetupCountryComponent },
          { path: 'branch', component: SetupBranchComponent },
          { path: 'package', component: SetupPackageComponent },
          { path: 'session', component: SetupSessionComponent },
          { path: 'nominal', component: SetupNorminalComponent },
          { path: 'sequence', component: SetupSequenceComponent },
          { path: 'smslog', component: SetupSmslogsComponent },
          { path: 'api', component: SetupSmsComponent },
          { path: 'users', component: SetupUserComponent },
          { path: 'teller', component: SetupTellerComponent },
          { path: 'transit', component: SetupTransitComponent },
          { path: 'location', component: SetupLocationComponent },
          { path: 'account', component: SetupAccountComponent }
        ]
      },
      { path: '404', component: NotfoundComponent, data: { title: 'Error Page' } },
      { path: '**', redirectTo: '404' }
    ]
  },
  { path: '**', redirectTo: '404' }
];
