import { Component, OnInit } from '@angular/core';
import { Router,  ActivatedRoute} from '@angular/router';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { ApiService } from 'src/app/services/api.service';
import { E } from '@angular/core/src/render3';


@Component({
  selector: 'app-view-customer',
  templateUrl: './view-customer.component.html',
  styleUrls: ['./view-customer.component.css']
})
export class ViewCustomerComponent implements OnInit {

  cust: any; accnt: any; id: string; rpt: any; statements: any[]=[]; expt:any[]=[];
  add: boolean; accounts: any[] = []; trans: any[] = []; response: any; search: string = '';
  type: any[] = [ "ALL","DEPOSIT", "WITHDRAWAL", "PENSION", "LOAN", "SAVINGS"];

  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
  }


  constructor(
    private api: ApiService, 
    private _use: NavComponent, 
    private _api: ManagerService, 
    private rout: Router, 
    private router: ActivatedRoute,
    private exp: ExportExcelService) { }

  ngOnInit() {
    this.id = this.router.snapshot.paramMap.get('id');
    this.getAllCustomers(this.id);
    // this.getAllCustomerAccount(this.id);
    this.rpt = { id: '', type: '', start: '', end: '', code: 0 }
  }


  getAllCustomers(id: any) {
    this.api.get("customer/view/"+id).subscribe((data: any) => {
      this.cust = data; this.accounts = data.accounts;
      this.trans = data.payments;
      console.log(data);
    });
  }

  getAllCustomerAccount(id: any) {
    this._api.getCustomerAccount(id).subscribe((data: any) => {
      this.accnt = data; 
      console.log(data);
    });
  }

  getACcountReport() {
    this.api.post("accounts/statement/",this.rpt).subscribe((data: any) => {
      this.statements = data;
      console.log(data);
    });
  }

 async export() {
    this.format().then((data:any[])=>{
      // return data;
      // const data = await this.format();
      this.exp.exportAsExcelFile(data, 'customer_transaction_'+this.cust.code);
    });
  }
 
  async format(){
    var id = 1; var exp = [];
    await this.statements.forEach((e: any) => {
      const ed = {
        SN: this.id, DATE: e.date, CODE: e.code, TYPE: e.type, MOBILE: e.mobile, SOURCE: e.source, METHOD: e.method,
        AMOUNT: e.amount, REFERENCE: e.reference, PAYMENT_STATUS: e.status_message, ENTERPRISE_STATUS: e.bank_status_message,
        TRANID: e.tranid, TRANSACTION_NO: e.transaction_no
      };
      exp.push(ed); id++;
    });
    return exp;
  }



}




