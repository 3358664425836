import { Component, OnInit } from '@angular/core';
import { Router,  ActivatedRoute} from '@angular/router';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-view-moto-insurance',
  templateUrl: './view-moto-insurance.component.html',
  styleUrls: ['./view-moto-insurance.component.css']
})
export class ViewMotoInsuranceComponent implements OnInit {

  insure: any;  id: string; rpt: any; 
  add: boolean; accounts: any[] = []; trans: any[] = []; response: any; search: string = '';
  type: any[] = [ "ALL","DEPOSIT", "WITHDRAWAL", "PENSION", "LOAN", "SAVINGS"];

  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
  }


  constructor(
    private api: ApiService, 
    private _use: NavComponent, 
    private _api: ManagerService, 
    private rout: Router, 
    private router: ActivatedRoute,
    private _exp: ExportExcelService) { }

  ngOnInit() {
    this.id = this.router.snapshot.paramMap.get('id');
    this.getAllInsurance(this.id);
    this.rpt = { id: '', type: '', start: '', end: '', code: 0 }
  }


  getAllInsurance(id: any) {
    this.api.get("customer/view/"+id).subscribe((data: any) => {
      this.insure = data; this.accounts = data.accounts;
      this.trans = data.payments;
      console.log(data);
    });
  }

}











