import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SetupService {
  private api = environment.apiUrl;
  constructor(private http: HttpClient) { }

  Upload(data: File, type): Observable<any> {
    const fd = new FormData();
    fd.append('file', data);
    return this.http.post(this.api + 'upload/' + type, fd);
  }

  getCode(type: string): Observable<any> {
    return this.http.get(this.api + 'sequence/code/' + type);
  }

  getSequences(): Observable<any> {
    return this.http.get(this.api + 'sequence');
  }

  getSequence(id: number): Observable<any> {
    return this.http.get(this.api + 'sequence/' + id);
  }

  postSequence(data): Observable<any> {
    return this.http.post(this.api + 'sequence', JSON.stringify(data));
  }

  putSequence(id: number, data): Observable<any> {
    return this.http.put(this.api + 'sequence/' + id, JSON.stringify(data));
  }

  delSequence(id: number): Observable<any> {
    return this.http.delete(this.api + 'sequence/' + id);
  }

  getCountries(): Observable<any> {
    return this.http.get(this.api + 'Country');
  }

  getCountry(id: number): Observable<any> {
    return this.http.get(this.api + 'Country/' + id);
  }

  postCountry(data): Observable<any> {
    return this.http.post(this.api + 'Country', JSON.stringify(data));
  }

  putCountry(id: number, data): Observable<any> {
    return this.http.put(this.api + 'Country/' + id, JSON.stringify(data));
  }

  delCountry(id: number): Observable<any> {
    return this.http.delete(this.api + 'Country/' + id);
  }

  getLocations(): Observable<any> {
    return this.http.get(this.api + 'location');
  }

  getLocation(id: number): Observable<any> {
    return this.http.get(this.api + 'location/' + id);
  }

  postLocation(data): Observable<any> {
    return this.http.post(this.api + 'location', JSON.stringify(data));
  }

  putLocation(id: number, data): Observable<any> {
    return this.http.put(this.api + 'location/' + id, JSON.stringify(data));
  }

  delLocation(id: number): Observable<any> {
    return this.http.delete(this.api + 'location/' + id);
  }

  getCompanys(): Observable<any> {
    return this.http.get(this.api + 'merchant');
  }

  getCompany(id: number): Observable<any> {
    return this.http.get(this.api + 'merchant/' + id);
  }

  postCompany(data): Observable<any> {
    return this.http.post(this.api + 'merchant', JSON.stringify(data));
  }

  putCompany(id: number, data): Observable<any> {
    return this.http.put(this.api + 'merchant/' + id, JSON.stringify(data));
  }

  delCompany(id: number): Observable<any> {
    return this.http.delete(this.api + 'merchant/' + id);
  }

  getNominals(): Observable<any> {
    return this.http.get(this.api + 'nominal');
  }

  getMainNominals(): Observable<any> {
    return this.http.get(this.api + 'nominal/main');
  }

  getNominalsByGlType(type: string): Observable<any> {
    return this.http.get(this.api + 'nominal/gltype/' + type);
  }

  getNominalsByBalance(type: string): Observable<any> {
    return this.http.get(this.api + 'nominal/balancetype/' + type);
  }

  getNominal(id: number): Observable<any> {
    return this.http.get(this.api + 'nominal/' + id);
  }

  postNominal(data): Observable<any> {
    return this.http.post(this.api + 'nominal', JSON.stringify(data));
  }

  putNominal(id: number, data): Observable<any> {
    return this.http.put(this.api + 'nominal/' + id, JSON.stringify(data));
  }

  delNominal(id: number): Observable<any> {
    return this.http.delete(this.api + 'nominal/' + id);
  }

  getAcctTypes(): Observable<any> {
    return this.http.get(this.api + 'accounttypetype');
  }

  getAcctType(id: number): Observable<any> {
    return this.http.get(this.api + 'accounttypetype/' + id);
  }

  postAcctType(data): Observable<any> {
    return this.http.post(this.api + 'accounttypetype', JSON.stringify(data));
  }

  putAcctType(id: number, data): Observable<any> {
    return this.http.put(this.api + 'accounttypetype/' + id, JSON.stringify(data));
  }

  delAcctType(id: number): Observable<any> {
    return this.http.delete(this.api + 'accounttypetype/' + id);
  }

  getSmsApis(): Observable<any> {
    return this.http.get(this.api + 'smsapi');
  }

  getSmsApi(id: number): Observable<any> {
    return this.http.get(this.api + 'smsapi/' + id);
  }

  postSmsApi(data): Observable<any> {
    return this.http.post(this.api + 'smsapi', JSON.stringify(data));
  }

  putSmsApi(id: number, data): Observable<any> {
    return this.http.put(this.api + 'smsapi/' + id, JSON.stringify(data));
  }

  delSmsApi(id: number): Observable<any> {
    return this.http.delete(this.api + 'smsapi/' + id);
  }

  getSmslogs(): Observable<any> {
    return this.http.get(this.api + 'sms');
  }

  getSmslog(id: number): Observable<any> {
    return this.http.get(this.api + 'sms/' + id);
  }

  postSmslog(data): Observable<any> {
    return this.http.post(this.api + 'sms', JSON.stringify(data));
  }

  putSmslog(id: number, data): Observable<any> {
    return this.http.put(this.api + 'sms/' + id, JSON.stringify(data));
  }

  delSmslog(id: number): Observable<any> {
    return this.http.delete(this.api + 'sms/' + id);
  }
  getTransits(): Observable<any> {
    return this.http.get(this.api + 'transit');
  }

  getTransit(id: number): Observable<any> {
    return this.http.get(this.api + 'transit/' + id);
  }

  postTransit(data): Observable<any> {
    return this.http.post(this.api + 'transit', JSON.stringify(data));
  }

  putTransit(id: number, data): Observable<any> {
    return this.http.put(this.api + 'transit/' + id, JSON.stringify(data));
  }

  delTransit(id: number): Observable<any> {
    return this.http.delete(this.api + 'transit/' + id);
  }

  getUsers(): Observable<any> {
    return this.http.get(this.api + 'auth/user');
  }

  getUser(id: number): Observable<any> {
    return this.http.get(this.api + 'auth/user/' + id);
  }

  resetUser(email): Observable<any> {
    return this.http.get(this.api + 'auth/reset/' + email);
  }

  postUser(data): Observable<any> {
    return this.http.post(this.api + 'auth/user', JSON.stringify(data));
  }

  putUser(id: string, data): Observable<any> {
    return this.http.put(this.api + 'auth/user/' + id, JSON.stringify(data));
  }

  delUser(id: number): Observable<any> {
    return this.http.delete(this.api + 'auth/user/' + id);
  }

  getCots(): Observable<any> {
    return this.http.get(this.api + 'cot');
  }

  getCot(id: number): Observable<any> {
    return this.http.get(this.api + 'cot/' + id);
  }

  postCot(data): Observable<any> {
    return this.http.post(this.api + 'cot', JSON.stringify(data));
  }

  putCot(id: number, data): Observable<any> {
    return this.http.put(this.api + 'cot/' + id, JSON.stringify(data));
  }

  delCot(id: number): Observable<any> {
    return this.http.delete(this.api + 'cot/' + id);
  }

  getAccountTypes(): Observable<any> {
    return this.http.get(this.api + 'accounttype');
  }

  getAccountType(id: number): Observable<any> {
    return this.http.get(this.api + 'accounttype/' + id);
  }

  postAccountType(data): Observable<any> {
    return this.http.post(this.api + 'accounttype', JSON.stringify(data));
  }

  putAccountType(id: number, data): Observable<any> {
    return this.http.put(this.api + 'accounttype/' + id, JSON.stringify(data));
  }

  delAccountType(id: number): Observable<any> {
    return this.http.delete(this.api + 'accounttype/' + id);
  }

}
