import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TellerService } from 'src/app/services/teller.service';
import { send } from 'q';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {
  @ViewChild('alertForm') @ViewChild('singleForm') public any: NgForm;
  send: any; sing: any; date: any; save: any; edit: boolean;
  cust: any[] = []; staff: any[] = []; merchants: any[]=[]; get: any; res: any;
  constructor(private _set: SetupService, private api:ApiService, private _api: ManagerService,
    private _get: TellerService, public _use: NavComponent) { }

  ngOnInit() {
    this.date = this._use.date;
    this.formReset();
    if(this._use.active.typ ==="Admin" || this._use.active.typ === "Support") {
      this.getMerchant();
    }
    // this.getAllCustomers();
  }

  getMerchant() {
    this.api.get('merchant?limit=100').subscribe((data: any) => {
      this.merchants = data.data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  getAllCustomers() {
    this._api.getCustomers().subscribe((data: any) => {
      this.cust = data.data; this.getAllC();
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Customer List Fail', 'Unable to Fetch Customer List');
    });
    // this._get.getEmployees().subscribe((data: any) => {
    //   this.staff = data.data; this.getAllS();
    // }, (err: HttpErrorResponse) => {
    //   this._use.showMsg('error', 'Staff List Fail', 'Unable to Fetch Employee List');
    // });
  }

  postBoardcast() {
    this._api.postSms(this.send).subscribe((data: any) => {
      // this.res = data;
      this._use.showMsg('success', 'Sms Sented', 'Sms Status' + data.response);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Sending Fail', 'Unable to Send Sms');
    });
  }

  postSingle() {
    this._set.postSmslog(this.sing).subscribe((data: any) => {
      // this.res = data;
      this._use.showMsg('success', 'Sms Sented', 'Sms Status' + data.response);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Sending Fail', 'Unable to Send Sms');
    });
  }

  postSms() {
    if (this.save.alertid > 0) {
      this._api.putAlert(this.save.alertid, this.save).subscribe((data: any) => {
        this.send = data; this.formReset();
        this._use.showMsg('success', 'Alert Updated', 'Alert Has been Updated Successfully');
      }, (err: HttpErrorResponse) => {
        this._use.showMsg('error', 'Alert Fail', 'Unable to Update Sms Alert');
      });
    } else {
      this._api.postAlert(this.save).subscribe((data: any) => {
        this.send = data; this.formReset();
        this._use.showMsg('success', 'Alert Saved', 'Alert Has been Saved Successfully');
      }, (err: HttpErrorResponse) => {
        this._use.showMsg('error', 'Alert Fail', 'Unable to Save Sms Alert');
      });
    }
  }

  getSms(value) {
    this._api.getAlertByType(value).subscribe((data: any) => {
      this.save = data;
    }, (err: HttpErrorResponse) => {
      this.save = {
      alertid: 0, type: value, message: this.save.message, name: false, auto: true,
      userid: this._use.active.id, merchantid: this._use.active.merchantid, date: new Date()
    };
      this._use.showMsg('error', 'Fetching Fail', 'No Saved Aler message for ' + value);
    });
  }

  getCust(id) {
    // console.log(id);
    this._api.getCustomer(id).subscribe((data: any) => {
      this.sing.mobile = data.mobile;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Get Customer Mobile');
    });
  }

  getAll(value) {
    this.send.mobile = '';
    this.send.count = 0;
    if (value === 'Staff') {
      this.getAllS();
    } else if (value === 'Customer') {
      this.getAllC();
    } else {
      this.getAllC(); this.getAllS();
    }
  }

  getAllC() {
    this.cust.forEach((e: any) => {
      if (e.mobile.length > 9) {
        this.send.mobile += e.mobile + ',';
        this.send.count += 1;
      }
    });
  }

  getAllS() {
    this.staff.forEach((e: any) => {
      if (e.mobile.length > 9) {
        this.send.mobile += e.mobile + ',';
        this.send.count += 1;
      }
    });
  }


  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.send = { mobile: '', message: '', option: 'All', count: 0,
        userid: this._use.active.id, merchantid: this._use.active.merchantid, date: new Date() };
      this.sing = { customerid: '', mobile: '', message: '', option: '',
        userid: this._use.active.id, merchantid: this._use.active.merchantid, date: new Date() };
      this.save = {
        alertid: 0, type: '', message: '', name: false, auto: true,
        userid: this._use.active.id, merchantid: this._use.active.merchantid, date: new Date()
      };
    }
  }

  
}
