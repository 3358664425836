import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent implements OnInit {
  accounts: any; filteredAccount: any[] = []; response: any; search: string = '';
  auto: any = { page: 1, limit: 10 }; show=false;
  merchants: any[] = []; rpt: any; 

  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1,10);
    // this.filteredAccount = this.serachResult(value);
  }
  constructor(private api: ApiService, private use: NavComponent, private _api: ManagerService, private exp: ExportExcelService) { }

  ngOnInit() {
    this.rpt = { merchantid: this.use.active.merchantid, type: '', start: '', end: '', code: 0 }
    this.getAllAccounts();
    if(this.use.active.typ=='Admin' || this.use.active.typ=='Support')this.getMerchant();
  }

  getMerchant() {
    this.api.get('Merchant?page=1&limit=100').subscribe((data: any) => {
      this.merchants = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  getAllAccounts() {
    this.api.get('accounts?page=1&limit=10').subscribe((data: any) => {
      this.accounts = data; this.response = data; console.log(data);
      this.filteredAccount = this.accounts.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  getMore(page,limit) {
    // console.info(this.searchTitle);
    this.api.get('accounts?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.accounts = data; this.response = data; console.log(data);
      this.filteredAccount = this.accounts.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  Delete(acct) {
    // console.info(this.searchTitle);
    this.api.delete('accounts/'+acct.accountid).subscribe((data: any) => {
      this.getMore(1,10);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  Export() {
    this.api.post('Accounts/Export', this.rpt).subscribe((data: any) => {
      this.exp.exportAsExcelFile(data, 'accounts');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Export Fail', 'Unable to Fetch Accounts List');
    });
    // this.api.get('accounts?page='+page+'&limit='+limit).subscribe((data: any)=>{
    //   this.filteredAccount = data.data;
    //   this.Sort().then((data)=>{
    //     // return data;
    //     this.exp.exportAsExcelFile(data, 'accounts');
    //   });
    // });
  }

  // Export() {
  //   this.Sort().then((data)=>{
  //     // return data;
  //     this.exp.exportAsExcelFile(data, 'account');
  //   });
  //   // this.exp.exportAsExcelFile(this.filteredAccount, 'account');
  // }

  async Sort() {
    const accounts = [];
    await this.filteredAccount.forEach((c)=>{
      const acct = { PolicyNumber: c.code, PolicyName: c.accounttype.name, Customer_Code: c.customer.code, Customer_Name: c.customer.code,  purpose: c.purpose, balance: c.balance, status: c.status, creationTime: c.date };
      accounts.push(acct);
    });
    return accounts;
  }

  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.accounts.filter(a => a.number.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.customer.fullname.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.accounttype.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.accounttype.basetype.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

}
